import React from "react"
// import Img from 'gatsby-image';
import { Container, Row, Col, Button } from "react-bootstrap"
import styles from "../scss/components/_InfoAndBackground.module.scss"
import Plx from "react-plx"

const InfoAndBackground = ({ info,isMobile }) => {
  const parallaxTitle = [
    {
      start: 150,
      duration: 300,
      properties: [
        {
          freeze: false,
          startValue: 1,
          endValue: 0,
          property: "opacity",
        },
        {
          startValue: 0,
          endValue: -100,
          property: "translateX",
        },
      ],
    },
  ]

  const parallaxButton = [
    {
      start: 150,
      duration: 300,
      properties: [
        {
          freeze: false,
          startValue: 1,
          endValue: 0,
          property: "opacity",
        },
        {
          startValue: 0,
          endValue: -100,
          property: "translateX",
        },
      ],
    },
  ]

  const parallaxImage2 = [
    {
      start: "self",
      duration: 500,
      properties: [
        {
          freeze: false,
          startValue: 1,
          endValue: 1,
          property: "scale",
        },
      ],
    },
  ]

  return (
    <Plx parallaxData={parallaxImage2}>
      <img src={isMobile ? info.imageMobile : info.imageDesktop} className={styles.imgBackground} />
      <div className={styles.InfoGradient}>
        <Container fluid className={styles.infoContainer}>
          <Row className="w-100 m-0">
            <Col md={6} xs={12} className={styles.InfoColumn}>
              {/* title */}
              {info.title && (
                <Plx parallaxData={parallaxTitle}>
                  <Row>
                    <Col>
                      <h1 className={styles.infoTitle}>{info.title}</h1>
                    </Col>
                  </Row>
                </Plx>
              )}

              {/* paragraph */}
              {info.paragraph && (
                <Plx parallaxData={parallaxButton}>
                  <Row>
                    <Col>
                      <p className={styles.infoParagraph}>{info.paragraph}</p>
                    </Col>
                  </Row>
                </Plx>
              )}

              {/* button */}
              {info.textButton && (
                <Plx parallaxData={parallaxButton}>
                  <Row>
                    <Col>
                      <Button
                        target="__blank"
                        href={info.linkButton}
                        className={styles.infoButton}
                        onClick={() => {
                          window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Content",
                            event_action: "El mañana en tus manos",
                            event_label: `${info.textButton.toUpperCase()}`,
                            interaction: true,
                            component_name: `boton_${info.textButton.toLowerCase()}`,
                            element_text:  `${info.textButton}`,
                          })
                        }}
                      >
                        {info.textButton}
                      </Button>
                    </Col>
                  </Row>
                </Plx>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Plx>
  )
}

export default InfoAndBackground
